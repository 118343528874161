import classNames from "classnames";
import Head from "next/head";
import React from "react";
import { Navbar } from "~/components/layout/Navbar";
import { useIsUserNoticesDisplayed, UserNotices } from "~/components/layout/UserNotices";

type Props = React.PropsWithChildren<{
  className?: string;
  title?: string;
  fluid?: boolean;
  fullscreen?: boolean;
}>;

export const Layout: React.FC<Props> = ({ className, title, fluid = false, fullscreen = false, children }) => {
  const fullTitle = title ? `${title} · Figures` : null;
  const { isUserNoticesDisplayed } = useIsUserNoticesDisplayed();

  return (
    <div className="flex h-full flex-col items-stretch justify-between">
      {fullTitle && (
        <Head>
          <title key="title">{fullTitle}</title>
        </Head>
      )}

      <div
        className={classNames({
          "flex h-full flex-col md:flex-row": true,
          "pt-8": isUserNoticesDisplayed,
        })}
      >
        <Navbar />

        <UserNotices className="fixed inset-x-0 top-[64px] z-50 h-8 shadow md:top-0 md:left-[84px]" />

        <main
          className={classNames(className, {
            "z-0 mt-[64px] flex flex-1 flex-col space-y-4 md:mt-0 md:ml-[84px]": true,
            "p-2 md:p-4": !fullscreen,
            "container": !fluid,
            "w-full md:w-[calc(100%-84px)] md:max-w-[calc(100%-84px)]": fluid,
          })}
        >
          {children}
        </main>
      </div>
    </div>
  );
};
