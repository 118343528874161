import { UserStatus } from "@prisma/client";
import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { createQuery } from "~/lib/reactQuery";

const handler: NextApiHandler<{ canSwitchCompanies: boolean }> = async (req, res) => {
  const user = getRequiredUser(req);

  const existingUserCount = await req.prisma.user.count({
    where: {
      email: user.email,
      status: UserStatus.JOINED,
    },
  });

  const isImpersonatingUser = !!req.impersonation?.user;
  const hasMoreThanOneUserAccount = existingUserCount > 1;
  const canSwitchCompanies = !isImpersonatingUser && hasMoreThanOneUserAccount;

  res.status(HttpStatus.OK).json({ canSwitchCompanies });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const CAN_SWITCH_COMPANIES_QUERY_KEY: ApiRoute["pathname"] = "/api/auth/can-switch-companies";

export const useCanSwitchCompaniesQuery = createQuery<typeof handler>({
  path: CAN_SWITCH_COMPANIES_QUERY_KEY,
});
